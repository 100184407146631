<template>
  <div class="deviceBorrowRemind" id="deviceBorrowRemind">
    <remind-item v-for="(item) in remindList" :data-info="item"></remind-item>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import RemindItem from '@views/shareAndDispatch/deviceBorrow/deviceBorrowRemind/remindItem'

export default {
  name: 'deviceBorrowRemind',
  components: { RemindItem },
  data() {
    return {
      remindList: [],
      loadingView: null,
      loading: false
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.updateDeviceBorrow)
  },
  mounted() {
    this.getDeviceList()
    this.addLocalStorageEventByKey('updateDeviceBorrow', this.updateDeviceBorrow)
  },
  methods: {
    updateDeviceBorrow:function() {
      this.getDeviceList();
    },
    startLoading: function() {
      let dom = document.getElementById('deviceBorrowRemind')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    getDeviceList: function() {
      let getList = () => {
        return new Promise(resolve => {
          this.$api.deviceBorrowModule
              .deviceOrderList({
                queryType: 4,
                efficiency: 1,
                page: 1,
                pageSize: 1000
              }).then(res => {
            if (typeof res['data'] == 'undefined' || res['data'] == null) {
              resolve({ result: false, list: [] })
              return
            }
            let remindList = []
            let nowTime = new Date().getTime()
            for (let i = 0; i < res['data']['length']; i++) {
              let info = res['data'][i]
              if (typeof info['estimatedEndTime'] == 'undefined' || info['estimatedEndTime'] == null) {
                continue
              }
              let estimatedEndTime = info['estimatedEndTime']
              let count = estimatedEndTime - nowTime
              info['leftCount'] = count
              if (count <= 0) {
                info['leftType'] = 'back'
              } else if (count > 0 && count <= 24 * 60 * 60 * 1000) {
                info['leftType'] = 'remind'
              } else {
                continue
              }
              remindList.push(info)
            }
            remindList.sort((a, b) => {
              let leftCount1 = a['leftCount']
              let leftCount2 = a['leftCount']
              return leftCount1 - leftCount2
            })
            resolve({ result: true, list: remindList })
          }).catch(e => {
            resolve({ result: false, list: [] })
          })
        })
      }

      this.loading = true
      getList().then(res => {
        this.loading = false
        this.remindList = res.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceBorrowRemind {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

</style>