<template>
  <div class="remindItem">
    <div class="remindItemContent">
      <cell_title_detail :show-line="false" head-title="设备名称" :content-title="deviceName"></cell_title_detail>
      <cell_title_detail :show-line="false" head-title="使用时长" :content-title="useTime"></cell_title_detail>
      <cell_title_detail :show-line="false" head-title="剩余时间" :content-title="leftTime" :color="color"
      ></cell_title_detail>
      <cell_title_detail :show-line="false" head-title="归还科室" :content-title="tenantName"></cell_title_detail>
      <div class="remindFooter">
        <el-button
            type="primary"
            round
            class="back"
            @click="checkClean"
        >
          归还
        </el-button>

        <el-button
            type="primary"
            round
            class="back"
            @click="toDeviceExtend"
        >
          延长使用时间
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Cell_title_detail from '@components/cell/cell_title_detail/cell_title_detail'
import { getTimeMsg } from '@utils/utils'

export default {
  name: 'remindItem',
  components: { Cell_title_detail },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    orderId() {
      if (this.$valueIsExist(this.dataInfo, 'orderId') == false) {
        return ''
      }
      return this.dataInfo['orderId']
    },
    id() {
      if (this.$valueIsExist(this.dataInfo, 'id') == false) {
        return ''
      }
      return this.dataInfo['id']
    },
    deviceName() {
      if (this.$valueIsExist(this.dataInfo, 'name') == false) {
        return ''
      }
      return this.dataInfo['name']
    },
    tenantName() {
      if (this.$valueIsExist(this.dataInfo, 'tenantName') == false) {
        return ''
      }
      return this.dataInfo['tenantName']
    },
    useTime() {
      if (this.$valueIsExist(this.dataInfo, 'estimatedStartTime') == false) {
        return ''
      }
      let nowTime = new Date().getTime()
      let count = nowTime - this.dataInfo['estimatedStartTime']
      if (count < 0) {
        return ''
      }
      return getTimeMsg(count / 1000.0, 1)
    },
    leftTime() {
      if (this.$valueIsExist(this.dataInfo, 'leftCount') == false) {
        return ''
      }
      let count = this.dataInfo['leftCount']
      if (count < 0) {
        return '超时' + getTimeMsg(count / 1000.0 * -1, 1)
      }
      return getTimeMsg(count / 1000.0, 1)
    },
    leftType() {
      if (this.$valueIsExist(this.dataInfo, 'leftType') == false) {
        return ''
      }
      return this.dataInfo['leftType']
    },
    color() {
      if (this.leftType == 'back') {
        return 'red'
      } else if (this.leftType == 'remind') {
        return 'yellow'
      }
      return 'black'
    }
  },
  methods: {
    checkClean() {
      this.$api.deviceBorrowModule
          .checkClean({
            id: this.orderId
          })
          .then((res) => {
            if (res.data == 1) {
              this.toDetails(this.dataInfo)
            } else {
              this.toGiveBackDetails(this.dataInfo)
            }
          })
          .catch((e) => {
          })
    },
    toDetails(item) {
      this.$push('deviceBorrow/disinfectDetails', {
        orderId: item.orderId
      })
    },
    toGiveBackDetails(item) {
      this.$push('deviceBorrow/giveBackDetails', {
        id: item.orderId,
        orderTime: item.orderTime
      })
    },
    toDeviceExtend() {
      this.$push('deviceBorrow/deviceBorrowExtend', {
        id: this.id,
        orderId: this.orderId,
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.remindItem {
  width: 100%;
  padding: 5px 10px 5px 10px;

  .remindItemContent {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(100, 101, 102, 0.12);
    border-radius: 8px;

    .remindFooter {
      width: 100%;
      padding: 5px 10px 10px 10px;
      display: flex;
      justify-content: flex-end;

      .back {

      }

      .remind {

      }

    }

  }

}

</style>